import * as React from "react";

import {
    Alert,
    AlertActionCloseButton,
    Button,
    Checkbox,
    PageSection,
    Spinner,
    TextInput,
    Title,
    ValidatedOptions,
} from '@patternfly/react-core';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiDelete, apiPost } from "linbit-api-fetcher";
import { useQueryClient } from "@tanstack/react-query";

import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import {
    AddContactRequest, Contact, ContractContact, ContractContactResponse, CreateContactRequest
} from 'linbit-api-fetcher';

import { t } from "i18next";
import { qkContractContacts, queryContractContacts } from "./fetcher";
import { isEmailValid } from "./util";


interface ContractContactsTableProps {
    contractId: number;
}

const ContractContactsTable: React.FunctionComponent<ContractContactsTableProps> = (props: ContractContactsTableProps) => {
    const [responseError, setResponseError] = React.useState('');
    const [editEmail, setEditEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);
    const [editTechnical, setEditTechnical] = React.useState(false);
    const [editSales, setEditSales] = React.useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    function deleteContractContact(contact_id: number) {
        apiDelete<String>("/my/contracts/" + props.contractId + "/contacts/" + contact_id)
            .then((_resp) => {
                queryClient.invalidateQueries(qkContractContacts(props.contractId));
            })
            .catch((reason) => setResponseError(reason.toString()));
    }

    const clearFields = () => {
        setEditEmail('');
        setEditTechnical(false);
        setEditSales(false);
        setEmailValidated(ValidatedOptions.default);
    }

    const handleAddClick = () => {
        let createContactReq: CreateContactRequest = {
            email: editEmail,
        };
        let addContactReq: AddContactRequest = {
            technical: editTechnical,
            sales: editSales,
        };
        let contact = contractContacts.find((cc) => cc.email === editEmail);
        if (contact) {
            if (contact.sales !== editSales || contact.technical !== editTechnical) {
                apiPost("/my/contracts/" + props.contractId + "/contacts/" + contact.id, addContactReq)
                    .then((_) => {
                        clearFields();
                        queryClient.invalidateQueries(qkContractContacts(props.contractId));
                    })
                    .catch((reason) => setResponseError(reason.toString()));
            }
        } else {
            apiPost<Contact>("/my/contacts", createContactReq)
                .then((resp) => {
                    apiPost("/my/contracts/" + props.contractId + "/contacts/" + resp.id, addContactReq)
                        .then((_) => {
                            clearFields();
                            queryClient.invalidateQueries(qkContractContacts(props.contractId));
                        })
                        .catch((reason) => setResponseError(reason.toString()));
                })
                .catch((reason) => setResponseError(reason.toString()));
        }
    }

    const handleEmailChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setEditEmail(value);
        setEmailValidated(isEmailValid(value));
    };

    const triggerEdit = (contractContact: ContractContact) => {
        setEditEmail(contractContact.email);
        setEditTechnical(contractContact.technical);
        setEditSales(contractContact.sales);
        setEmailValidated(isEmailValid(contractContact.email));
    }

    function renderContractContactRow(contractContacts: ContractContact[]) {
        return contractContacts
            .sort((a, b) => {
                return a.email.localeCompare(b.email);
            }).map((cc) => {
                return (
                    <Tr key={cc.id}>
                        <Td>{cc.email}</Td>
                        <Td textCenter>
                            <Checkbox id={"technical-" + cc.id} style={{ cursor: "default" }} checked={cc.technical} />
                        </Td>
                        <Td textCenter>
                            <Checkbox id={"sales-" + cc.id} style={{ cursor: "default" }} checked={cc.sales} />
                        </Td>
                        <Td textCenter>
                            <Button variant='plain' isSmall onClick={() => triggerEdit(cc)}><PencilAltIcon /></Button>
                            <Button variant='plain' isSmall onClick={() => deleteContractContact(cc.id)}><TrashIcon /></Button>
                        </Td>
                    </Tr>
                )
            })
    }

    const { isLoading, isError, data, error } = queryContractContacts(props.contractId);

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractContacts = data?.list ?? [];

    return (
        <React.Fragment>
            {responseError && (<Alert
                variant='danger'
                title={responseError}
                actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
            />)}
            <TableComposable
                variant='compact'
            >
                <Thead>
                    <Tr>
                        <Th>{t('common.email')}</Th>
                        <Th textCenter>{t('common.technical')}</Th>
                        <Th textCenter>{t('common.sales')}</Th>
                        <Th textCenter>{t('contracts.actions')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderContractContactRow(contractContacts)}
                    <Tr>
                        <Td>
                            <TextInput
                                type='email'
                                id='contract-email'
                                name='contract-email'
                                value={editEmail}
                                onChange={handleEmailChange}
                                validated={emailValidated}
                            />
                        </Td>
                        <Td textCenter>
                            <Checkbox
                                id="check-technical"
                                name="check-technical"
                                isChecked={editTechnical}
                                onChange={(checked, _event) => { setEditTechnical(checked) }}
                            />
                        </Td>
                        <Td textCenter>
                            <Checkbox
                                id="check-sales"
                                name="check-sales"
                                isChecked={editSales}
                                onChange={(checked, _event) => { setEditSales(checked) }}
                            />
                        </Td>
                        <Td textCenter>
                            <Button
                                onClick={handleAddClick}
                                isDisabled={emailValidated !== ValidatedOptions.success}
                                isSmall>
                                {contractContacts.find((cc) => cc.email === editEmail) ? t('common.save') : t('common.add')}
                            </Button>&nbsp;
                            <Button
                                onClick={clearFields}
                                isDisabled={editEmail === ''}
                                isSmall>
                                {t('common.clear')}
                            </Button>
                        </Td>
                    </Tr>
                </Tbody>
            </TableComposable>
            <br />
            <Button variant='secondary' onClick={() => navigate(-1)}>Back</Button>
        </React.Fragment>
    )
}

const ContractContactsPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const contractId = params.contractId ? +params.contractId : 0;

    return (
        <PageSection className="centered">
            <Title headingLevel={"h1"}>{t('contracts.edit-contacts')} {contractId}</Title><br />
            <ContractContactsTable contractId={contractId} />
        </PageSection>
    )
}

export { ContractContactsPage }
