import {
    Title, Text, TextVariants, PageSection, Spinner, ClipboardCopy, ClipboardCopyVariant
} from "@patternfly/react-core";
import { TableComposable, Thead, Tr, Th, Tbody, Td, ExpandableRowContent } from "@patternfly/react-table";
import { useQueries } from "@tanstack/react-query";
import { t } from "i18next";
import { apiGet } from "linbit-api-fetcher";
import { MyRepoConfigsResponse, NodeConfig, Repo } from "linbit-api-fetcher/dist/structs";
import * as React from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { queryContracts, queryDistributions } from "./fetcher";

const RepoUrlTable: React.FunctionComponent<{}> = () => {
    const [rowsExpanded, setRowsExpanded] = React.useState<{ [key: string]: boolean }>({});

    const qRDistris = queryDistributions();
    const qryContracts = queryContracts();

    async function fetchNodesRepos(contractId: number): Promise<MyRepoConfigsResponse> {
        let uri = '/my/contracts/' + contractId + '/nodes-repos';
        return apiGet<MyRepoConfigsResponse>(uri);
    }

    const distris = qRDistris.data ? qRDistris.data : [];
    const contracts = qryContracts.data ? qryContracts.data.list : [];
    const queriesNodesRepos = useQueries({
        queries: contracts.map(contract => {
            return {
                queryKey: ['contracts', contract.id, ['nodes-repos']],
                queryFn: () => fetchNodesRepos(contract.id),
            }
        })
    });

    for (const qry of queriesNodesRepos) {
        if (qry.isLoading) {
            return (<Spinner isSVG />);
        }
    }

    let nodeConfigs: NodeConfig[] = [];
    for (const qry of queriesNodesRepos) {
        if (qry.data) {
            nodeConfigs = nodeConfigs.concat(qry.data.nodes);
        }
    }

    const getDistri = (distriId: number | undefined | null) => {
        if (distriId && distriId in distris) {
            return distris[distriId];
        }
        return { id: 0, name: "Unknown", show: true, strictcheck: false };
    }

    const isRowExpanded = (url_hash: string) => {
        return url_hash in rowsExpanded ? rowsExpanded[url_hash] : false;
    }

    const onRowExpanded = (url_hash: string) => {
        const exp = { ...rowsExpanded };
        if (url_hash in rowsExpanded) {
            exp[url_hash] = !rowsExpanded[url_hash];
        } else {
            exp[url_hash] = true;
        }
        setRowsExpanded(exp);
    }

    const renderRepoConfigs = (repoConfigs: { [key: string]: Repo }) => {
        return Object.values(repoConfigs).map((repo, index) => {
            return (
                <React.Fragment key={index}>
                    <ClipboardCopy
                        isReadOnly
                        style={{ fontSize: "0.9em" }}
                        variant={ClipboardCopyVariant.inlineCompact}
                        hoverTip="Copy to clipboard"
                        clickTip="Copied">
                        {repo.config}
                    </ClipboardCopy>
                    {index != Object.keys(repoConfigs).length - 1 ?
                        <React.Fragment><br /><br /></React.Fragment> : null}
                </React.Fragment>
            )
        });
    }

    const renderRepos = (repos: NodeConfig[]) => {
        return repos.map((n, index) => {
            const expanded = isRowExpanded(n.url_hash);
            const distri = getDistri(n.distribution_id);
            const firstRepoKey = Object.keys(n.repos)[0];
            const firstRepo = n.repos[firstRepoKey];
            let repoUrl = null;
            if (firstRepo) {
                const conf = firstRepo.config;
                repoUrl = conf.substring(4, conf.lastIndexOf('/') + 1);
                if (conf.startsWith('baseurl=')) {
                    repoUrl = conf.substring(8, conf.lastIndexOf(firstRepoKey));
                } else {
                    repoUrl += 'dists/' + conf.substring(conf.lastIndexOf('/') + 2);
                }
            }
            return (
                <Tbody key={'body-' + n.url_hash}>
                    <Tr key={n.url_hash}>
                        <Td>{n.hostname}</Td>
                        <Td><Link to={"/contracts/#cluster-" + n.cluster_id}>{n.cluster_id}</Link></Td>
                        <Td>{repoUrl ? <a href={repoUrl}>{distri.name}</a> : <span>{distri.name}</span>}</Td>
                        <Td expand={{
                            rowIndex: index,
                            isExpanded: expanded,
                            onToggle: () => onRowExpanded(n.url_hash),
                        }} />
                    </Tr>
                    <Tr isExpanded={expanded}>
                        <Td colSpan={3}>
                            <ExpandableRowContent>
                                {renderRepoConfigs(n.repos)}
                            </ExpandableRowContent>
                        </Td>
                        <Td />
                    </Tr>
                </Tbody>
            )
        });
    }

    return (
        <TableComposable aria-label="repo url table" variant="compact">
            <Thead>
                <Tr>
                    <Th>{t('downloads.node-name')}</Th>
                    <Th width={10}>{t('contracts.cluster-id')}</Th>
                    <Th>{t('downloads.repository-url')}</Th>
                    <Th width={10}>{t('downloads.config')}</Th>
                </Tr>
            </Thead>
            {renderRepos(nodeConfigs)}
        </TableComposable>
    )
}

const DownloadsPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    return (
        <PageSection className="centered" variant="light">
            <Title headingLevel="h1" style={{ textAlign: "center" }}>{t('downloads.downloads')}</Title><br />
            <Text component={TextVariants.p} style={{ textAlign: "center" }}>
                <strong><Trans i18nKey='downloads.preferred-way'>
                    The preferred way to configure the LINBIT repositories is to use the
                    <Link to="/register-nodes">Linbit Register-nodes</Link> script.
                </Trans></strong>
            </Text><br /><br />
            <Text component={TextVariants.p} style={{ textAlign: "center" }}>
                <Trans i18nKey='downloads.manual-repo-config'>
                    Manual repo config (<strong>discouraged</strong>):
                </Trans>
            </Text>
            <RepoUrlTable />
        </PageSection>
    )
}

export { DownloadsPage }
