import * as React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import { App } from "./app";
import './app.css';
import './i18n';
import { ChangelogPage } from "./page-changelog";
import { ContractsPage } from "./page-contracts";
import { AppLogin } from "./page-login";
import { SupportPage } from "./page-support";
import { DownloadsPage } from "./page-downloads";
import { PageSection, Title } from "@patternfly/react-core";
import { ContactUsPage } from "./page-contact-us";
import { RegisterNodesPage } from "./page-register-nodes";
import { AccountPage } from "./page-account";
import { parseJwt, setupLAF } from "linbit-api-fetcher";
import { EditClusterPage } from "./page-edit-cluster";
import { EditNodePage } from "./page-edit-node";
import { ContractContactsPage } from "./page-contract-contacs";
import { ContractTicketPage } from "./page-contract-tickets";
import { ResetPasswordPage } from "./page-reset-password";
import { RoadmapPage } from "./page-roadmap";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60
        }
    }
});

const NotFound: React.FunctionComponent = () => {
    function GoHomeBtn() {
        const navigate = useNavigate();
        function handleClick() {
            navigate('/', { replace: true });
        }
        return (
            <button onClick={handleClick}>Take me home</button>
        );
    }

    return (
        <PageSection>
            <Title headingLevel={"h1"}>Not found</Title>
        </PageSection>
    )
};

const Main: React.FunctionComponent = () => {
    const [isLoged, setIsLoged] = React.useState(false);

    const onHandleLogin = (value: boolean) => {
        setIsLoged(value);
        queryClient.invalidateQueries();
    }

    const onHandleLogout = () => {
        // apiPost("/logout", {});
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        setIsLoged(false);
        window.location.href = '/'; // cannot use navigate here
    }

    React.useEffect(() => {
        let value = localStorage.getItem('access-token');
        if (value) {
            let jwt = parseJwt(value);
            let curExp = jwt["exp"] * 1000;
            if (curExp < Date.now() && localStorage.getItem('refresh-token')) {
                console.log("we need to refresh");
            }
            setIsLoged(true);
        } else {
            setIsLoged(false);
        }
    }, []);

    return (
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                {!isLoged ?
                    <Routes>
                        <Route path="/" element={<AppLogin handleLogin={onHandleLogin} />} />
                        <Route path="reset-password" element={<ResetPasswordPage />} />
                        <Route path="*" element={<AppLogin handleLogin={onHandleLogin} />} />
                    </Routes>
                    :
                    <QueryClientProvider client={queryClient}>
                        <Routes>
                            <Route path="/" element={<App handleLogout={onHandleLogout} />}>
                                <Route index element={<ContractsPage />} />
                                <Route path="contracts">
                                    <Route index element={<ContractsPage />} />
                                    <Route path=":contractId">
                                        <Route path="clusters">
                                            <Route index element={<EditClusterPage />} />
                                            <Route path=":clusterId">
                                                <Route index element={<EditClusterPage />} />
                                                <Route path="nodes">
                                                    <Route index element={<EditNodePage />} />
                                                    <Route path=":nodeId" element={<EditNodePage />} />
                                                </Route>
                                            </Route>
                                        </Route>
                                        <Route path="contacts">
                                            <Route index element={<ContractContactsPage />} />
                                        </Route>
                                        <Route path="tickets">
                                            <Route index element={<ContractTicketPage />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="register-nodes" element={<RegisterNodesPage />} />
                                <Route path="account" element={<AccountPage />} />
                                <Route path="downloads" element={<DownloadsPage />} />
                                <Route path="support" element={<SupportPage />} />
                                <Route path="contact" element={<ContactUsPage />} />
                                <Route path="changelog" element={<ChangelogPage />} />
                                <Route path="roadmap" element={<RoadmapPage />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </QueryClientProvider>
                }
            </I18nextProvider>
        </BrowserRouter>
    )
};

// const rootElement = document.getElementById("root");
// if (rootElement) {
//     const root = ReactDOMClient.createRoot(rootElement);
//     root.render(<Main />);
// }

setupLAF(process.env.REACT_APP_API_HOST);
const app = document.getElementById("root");
const root = createRoot(app!);
root.render(<Main />);
