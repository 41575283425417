import * as React from "react";
import { ActionGroup, Alert, Button, Form, FormAlert, FormGroup, PageSection, TextInput, Title } from "@patternfly/react-core";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiGet, apiPost, apiPut, Cluster, CreateClusterRequest, CreateNodeRequest, ModifyClusterRequest } from "linbit-api-fetcher";
import { useQueryClient } from "@tanstack/react-query";
import { NodeForm } from "./page-edit-node";

const EditClusterPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [clusterName, setClusterName] = React.useState('');

    const [hostname, setHostname] = React.useState('');
    const [macAddresses, setMacAddresses] = React.useState<Array<string>>([]);
    const [ibAddresses, setIbAddresses] = React.useState<Array<string>>([]);
    const [distributionId, setDistributionId] = React.useState(0);

    const isCreate = params.clusterId === undefined;

    React.useEffect(() => {
        if (!isCreate) {
            apiGet<Cluster>('/my/contracts/' + params.contractId + '/clusters/' + params.clusterId)
                .then((resp) => {
                    setClusterName(resp.documentation ? resp.documentation : "");
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        }
    }, []);

    const onClusterNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setClusterName(value);
    };

    const onHostnameChange = (value: string) => {
        setHostname(value);
    };

    const onDistriChanged = (distriId: number) => {
        setDistributionId(distriId);
    }

    const onSaveClick = () => {
        const CLUSTER_URL = '/my/contracts/' + params.contractId + '/clusters';
        if (isCreate) {
            let createClusterReq: CreateClusterRequest = {
                documentation: clusterName
            };
            apiPost<Cluster>(CLUSTER_URL, createClusterReq)
                .then((newCluster) => {
                    queryClient.invalidateQueries(['contracts', params.contractId, 'clusters']);

                    const contractId = params.contractId ? +params.contractId : 0;
                    let createNodeReq: CreateNodeRequest = {
                        hostname: hostname,
                        mac_addresses: macAddresses,
                        ib_addresses: ibAddresses,
                        ip_addresses: [],
                        current_contract: contractId,
                        // comment: comment,
                    }
                    if (distributionId !== 0) {
                        createNodeReq.distribution_id = distributionId;
                    }
                    console.log(createNodeReq);

                    const NODE_URL = CLUSTER_URL + "/" + newCluster.id + "/nodes";
                    apiPost<Node>(NODE_URL, createNodeReq)
                        .then((_resp) => {
                            queryClient.invalidateQueries(
                                ['contracts', params.contractId, 'clusters', newCluster.id]);
                            navigate("/contracts/#cluster-" + newCluster.id);
                        })
                        .catch((reason) => {
                            setErrorMessage(reason.toString());
                        });
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        } else {
            let modifyReq: ModifyClusterRequest = {
                documentation: clusterName
            }
            console.log(modifyReq);

            const CLUSTER_URL = '/my/contracts/' + params.contractId + '/clusters/' + params.clusterId;
            apiPut<Cluster>(CLUSTER_URL, modifyReq)
                .then((_resp) => {
                    queryClient.invalidateQueries(['contracts', params.contractId, 'clusters']).then((_) => {
                        navigate("/contracts/#cluster-" + params.clusterId);
                    });
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        }
    }

    const saveDisabled = () => {
        return isCreate ? hostname === "" : false;
    }

    return (
        <PageSection className="centered">
            <Title headingLevel={"h1"}>{isCreate ? t('edit-cluster.add') : t('edit-cluster.edit')}</Title><br />
            <Form className="centered">
                {errorMessage && (
                    <FormAlert>
                        <Alert
                            variant='danger'
                            title={errorMessage}
                            isInline
                        />
                    </FormAlert>
                )}
                <FormGroup label={t('edit-cluster.cluster-name')} fieldId='cluster-name'>
                    <TextInput id="cluster-name" aria-label="cluster name input"
                        value={clusterName} onChange={onClusterNameChange} />
                </FormGroup>
                {isCreate ? <NodeForm
                    hostname={hostname}
                    macAddresses={macAddresses}
                    ibAddresses={ibAddresses}
                    distributionId={distributionId}
                    onHostnameChange={onHostnameChange}
                    onMacAddressesChange={(vals) => setMacAddresses(vals)}
                    onIbAddressesChange={(vals) => setIbAddresses(vals)}
                    onDistriChanged={onDistriChanged} /> : null}
                <ActionGroup>
                    <Button
                        variant="primary"
                        onClick={onSaveClick}
                        isDisabled={saveDisabled()}>{isCreate ? t('contracts.create') : t('common.save')}</Button>
                    <Link to={`/contracts`}><Button variant="secondary">{t('common.cancel')}</Button></Link>
                </ActionGroup>
            </Form>
        </PageSection>
    )
}

export { EditClusterPage }
