"use strict";
exports.__esModule = true;
require('./content.css');
exports.default = {
  "content": "pf-c-content",
  "modifiers": {
    "visited": "pf-m-visited",
    "plain": "pf-m-plain",
    "overpassFont": "pf-m-overpass-font"
  }
};