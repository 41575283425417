{
    "translation": {
        "common": {
            "add": "追加",
            "save": "セーブ",
            "clear": "クリア",
            "back": "戻る",
            "email": "メール",
            "technical": "テクニカル",
            "sales":"セールス",
            "error":"エラー",
            "yes": "はい",
            "cancel": "キャンセル"
        },
        "contracts": {
            "contracts": "契約",
            "actions": "目的",
            "support-level": "サポート",
            "customer": "お客様",
            "created": "で作成されました",
            "expires": "有効期限が切れます",
            "account-manager": "アカウントマネージャー",
            "contact-emails": "お問い合わせメール",
            "nodes-allowed": "許容される最大ノード数",
            "nodes-count": "ノード数",
            "cluster-name": "クラスタ名",
            "cluster-id": "クラスタ ID",
            "nodes": "サポートされているノード",
            "name": "名前",
            "distribution": "ディストリビューション",
            "drbd-version": "DRBDバージョン",
            "proxy-version": "Proxyバージョン",
            "add-cluster": "クラスタを追加",
            "edit-cluster": "クラスターの編集",
            "add-node": "クラスタにノードを追加する",
            "edit-node": "ノードの編集",
            "delete-node": "ノードを削除",
            "download-packages": "パッケージをダウンロード",
            "download-license": "ダウンロードライセンス",
            "owner": "オーナー",
            "type": "タイプ",
            "issued": "発行済み",
            "create": "作成",
            "sales-manager": "アカウント管理者",
            "phone": "電話",
            "tickets": "切符売場",
            "edit-contacts": "契約の連絡先を編集する",
            "really-delete-node": "本当にノードを削除しますか",
            "really-delete-node-text": "The node will be removed from cluster and contract.",
            "licenses": "ライセンス"
        },
        "edit-cluster": {
            "add": "クラスタを契約に追加",
            "edit": "契約のクラスターを編集",
            "cluster-name": "クラスタ名"
        },
        "edit-node": {
            "hostname": "ホスト名",
            "mac-addresses": "MAC Addresses",
            "ib-guids": "IB GUIDs"
        },
        "register-nodes": {
            "register-nodes": "レジスタノード",
            "header": "ノードを管理するには、manage-node コマンド ライン ユーティリティをダウンロードします",
            "download-button-text": "コマンドラインユーティリティのダウンロード",
            "we-invite-you": "使用方法は、このツールを対象ノードの任意ディレクトリ(/tmpなど)に配置し、以下のように実行するだけです。 <1>コマンドラインツール</1> を、是非ご利用ください。",
            "to-use-it-save": "数あるリポジトリの中から、最適なリポジトリを自動的に構成してくれる:",
            "interactive-mode": "非対話モード:",
            "non-interactive-mode": "非インタラクティブモード:",
            "non-interactive-description": "現在、このモードはdrbd-proxyに関しては、最新のdrbd-proxyライセンスファイルへの自動保存のみを行います。",
            "script-description": "(※ 本スクリプトの実行には、Python 2.6以上のバージョンが必須です( python --version でバージョンを確認できます )。"
        },
        "account": {
            "account": "アカウント詳細",
            "change-password": "パスワードの変更",
            "old-password": "現在ご利用のパスワード",
            "new-password": "新しいパスワード",
            "retype-password": "新しいパスワード再入力",
            "short-password": "パスワードは 8 文字以上である必要があります",
            "password-must-match": "パスワードが一致しません",
            "password-changed": "パスワードは正常に変更されました"
        },
        "support": {
            "web": "ウェブ",
            "support": "サポート",
            "ticket-paragraph": "チケットを提出すると、あなたのsyslogのタールを塗ったgzipファイル（TGZ）を含めてください、関連する設定ファイル（/etc/drbd.d/*をヘクタール、ログインして任意の特定のデーモン設定ファイルの元と一緒に/etc/ha.d/*。の/ etc/ mysqlの/*）。",
            "urgent-paragraph": "緊急の依頼がある場合は、新しいチケットを開いてください。電子メールで緊急の依頼をする場合は、新しいメールを書き、以前のメッセージに返信しないでください。既存のチケットにお客様が新たに入力されても、リンビットのサポートエージェントが営業時間外に通知されることはありません。",
            "otrs-paragraph": "<1>web interface of the ticket system</1> 上では、既存のすべてのサポートチケットだけでなく、新しいオープニングサポートチケットを確認することができます。",
            "email": "メール",
            "open-new-tickets": "あなたのメールアドレスは、少なくとも一つの契約で「連絡先メールアドレス」としてリストされている場合は、 <1>{{suppportMail}}</1> に電子メールで新しいチケットを開くことができます。（契約]タブを参照してください。）",
            "phone-support": "電話サポート",
            "general-notes": "お問い合わせについて",
            "urgent-requests": "緊急のご要望",
            "office-hours": "営業時間",
            "choose-credential-title": "サポートのログイン資格情報を選択してください",
            "select-list-description": "ログインに使用するサポート資格情報を選択してください:"
        },
        "downloads": {
            "downloads": "ダウンロード",
            "node-name": "ノードの名前",
            "repository-url": "リポジトリー URL",
            "config": "設定"
        },
        "contact-us": {
            "contact-us": "お問い合わせ",
            "sales": "セールス",
            "your-sales-manager": "アカウント管理者 {{accountManager}}, <3>{{number}}</3> と <5>{{accountManagerEmail}}</5>",
            "to-open-a-ticket": "チケットを開くには、にメールを送ります <1>{{supportMail}}</1> 次の電子メールアドレスのいずれかから:"
        },
        "tickets": {
            "ticketnumber": "チケット番号",
            "subject": "主題",
            "accounted-time": "サポート時間",
            "status": "スターテス",
            "for-contract": "契約のチケット"
        },
        "reset": {
            "title": "パスワード再設定リクエスト",
            "token": "トークン",
            "set-password": "パスワードを設定してください",
            "request-reset": "リセットをリクエスト",
            "request-reset-description": "指定された電子メールのパスワードのリセットをリクエストします。<br />指定された電子メール アドレスの確認リンクを受け取り、新しいパスワードを設定できます。",
            "reset-requested-title": "パスワードのリセットがリクエストされました",
            "reset-requested-message": "指定されたアドレスにメールが送信されました。メールの指示に従ってください。",
            "reset-password-description": "有効なトークンがあれば、ここで新しいパスワードを設定できます。",
            "reset-password-done-title": "新しいパスワードが設定されました",
            "reset-password-done-message": "ログインページに戻り、新しいパスワードを使用できます。"
        },
        "login": {
            "title": "LINBIT アカウントにログインします",
            "subtitle": "LINBIT カスタマー ポータルのメール アドレスとパスワードを使用する",
            "keep-logged-in": "7 日間ログインしたままにします。",
            "username": "メール",
            "password": "パスワード",
            "login": "ログインする",
            "reset-password": "パスワードを再設定する？"
        }
    }
}
