import * as React from "react";
import { Title, Text, TextVariants, PageSection, List, ListItem } from "@patternfly/react-core";
import { Trans, useTranslation } from 'react-i18next';
import EnvelopeIcon from '@patternfly/react-icons/dist/esm/icons/envelope-icon';
import { MyAccountInfo, UserInfo } from "linbit-api-fetcher/dist/structs";
import { apiGet } from "linbit-api-fetcher";
import { useQueries, useQuery } from "@tanstack/react-query";
import { PhoneSupportSection } from "./page-support";
import { fetchContractContacts, qkContractContacts, queryContracts } from "./fetcher";


const ContactUsPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    async function fetchMyAccountInfo(): Promise<MyAccountInfo> {
        let uri = '/my/account/info';
        return apiGet<MyAccountInfo>(uri);
    }

    async function fetchUserInfo(uid: string | undefined | null): Promise<UserInfo> {
        let uri = '/my/user-info/' + uid;
        return apiGet<UserInfo>(uri);
    }

    const { data: myInfo } =
        useQuery<MyAccountInfo, string>(['my', 'account', 'info'], fetchMyAccountInfo);

    const { data: accountManagerInfo } = useQuery<UserInfo, string>({
        queryKey: ['my', 'user-info', myInfo?.account_manager],
        queryFn: () => fetchUserInfo(myInfo?.account_manager),
        enabled: !!myInfo?.account_manager
    });

    const qryContracts = queryContracts();

    const contracts = qryContracts.data ? qryContracts.data.list : [];
    const queriesContacts = useQueries({
        queries: contracts.map(contract => {
            return {
                queryKey: qkContractContacts(contract.id),
                queryFn: () => fetchContractContacts(contract.id),
            }
        })
    });

    const allContacts = queriesContacts.flatMap((qry) => {
        if (qry.data) {
            return qry.data.list;
        }
        return [];
    });

    const accountManager = accountManagerInfo ? accountManagerInfo.name : "?";
    const number = accountManagerInfo?.phone_number ? accountManagerInfo.phone_number : "+43-1-8178292-0";
    const accountManagerEmail = accountManagerInfo?.email ? accountManagerInfo.email : "sales@linbit.com";

    const supportMail = "support@linbit.com";
    return (
        <PageSection className="centered" variant="light">
            <Title headingLevel="h1">{t('contact-us.sales')}</Title><br />
            <Text component={TextVariants.p}><Trans i18nKey='contact-us.your-sales-manager'>
                Your sales account manager is {{ accountManager }}, <a href={"tel:" + number}>{{ number } as any}</a> and <a href={"mailto:" + accountManagerEmail}>{{ accountManagerEmail } as any}</a>
            </Trans></Text><br />
            <Title headingLevel="h1">{t('support.support')}</Title><br />
            <Text component={TextVariants.p}><Trans i18nKey='contact-us.to-open-a-ticket'>
                To open a ticket send an eMail to <a href={"mailto:" + supportMail}>{{ supportMail } as any}</a> from one of the following eMail addresses:
            </Trans></Text><br />
            <List className="centered" iconSize="large">
                {allContacts.map((cc) => cc.email)
                    .filter((val, idx, self) => self.indexOf(val) === idx)
                    .map((email) => <ListItem key={email} icon={<EnvelopeIcon />}>{email}</ListItem>)}
            </List><br />
            <PhoneSupportSection />
        </PageSection >
    )
}

export { ContactUsPage }
