import * as React from "react";
import {
    Title, PageSection, Grid, GridItem, Card, CardHeader, CardBody, CardTitle, CardActions, Button, CardHeaderMain, Alert, List, ListItem
} from "@patternfly/react-core";
import { Trans, useTranslation } from 'react-i18next';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from "@patternfly/react-icons";
import { RoadmapFeature, RoadmapFeaturesResponse, RoadmapRankingResponse } from "linbit-api-fetcher/dist/structs";
import { apiDelete, apiGet, apiPost, apiPut } from "linbit-api-fetcher";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ReactMarkdown from "react-markdown";

interface FeatureCardProps {
    id: number;
    title: string;
    description: string;
    position: number;
    sortable?: boolean;
    onMoveToFavorites: (id: number) => void;
    onMoveToPlanned: (id: number) => void;
    onVoteUp: (id: number, position: number) => void;
    onVoteDown: (id: number, position: number) => void;
}

const FeatureCard: React.FunctionComponent<FeatureCardProps> = (props: FeatureCardProps) => {
    const positionText = props.sortable ? props.position + ". " : "";
    return (
        <Card>
            <CardHeader>
                {props.sortable ? <CardHeaderMain>
                    <Button onClick={() => props.onVoteUp(props.id, props.position - 1)}><ArrowUpIcon /></Button>&nbsp;
                    <Button onClick={() => props.onVoteDown(props.id, props.position + 1)} ><ArrowDownIcon /></Button></CardHeaderMain>
                    : <CardHeaderMain><Button onClick={() => props.onMoveToFavorites(props.id)}><ArrowLeftIcon /></Button></CardHeaderMain>}
                <CardActions hasNoOffset>{
                    props.sortable && <Button onClick={() => props.onMoveToPlanned(props.id)}><ArrowRightIcon /></Button>}
                </CardActions>
            </CardHeader>
            <CardTitle>{positionText}{props.title}</CardTitle>
            <CardBody><ReactMarkdown components={{
                p: ({node, ...props}) => <p className="markdown" {...props} />,
                ul: ({node, ...props}) => <ul className="pf-c-list markdown" {...props} />,
                ol: ({node, ...props}) => <ol className="pf-c-list markdown" {...props} />
            }}>{props.description}</ReactMarkdown></CardBody>
        </Card>
    )
}

const FeatureGrid: React.FunctionComponent<{}> = () => {
    const queryClient = useQueryClient();
    const QK_RANKING = ['my', 'roadmap', 'ranking'];

    const [errorMsg, setErrorMsg] = React.useState("");

    async function fetchFeatures(): Promise<RoadmapFeature[]> {
        let uri = '/my/roadmap/features';
        return (await apiGet<RoadmapFeaturesResponse>(uri)).list;
    }

    async function fetchRoadmapRanks(): Promise<number[]> {
        let uri = '/my/roadmap/ranking';
        return (await apiGet<RoadmapRankingResponse>(uri)).ranking;
    }

    const { data } =
        useQuery<RoadmapFeature[], string>(['my', 'roadmap', 'features'], fetchFeatures);

    const { data: userRanking } =
        useQuery<number[], string>(QK_RANKING, fetchRoadmapRanks);

    const features = data ? data : [];

    const userVotes: number[] = userRanking ? userRanking : [];
    const planned: number[] = features.filter(f => !userVotes.find((uv) => uv === f.id)).map(f => f.id);

    const onMoveToFavorites = (id: number) => {
        apiPost<RoadmapRankingResponse>('/my/roadmap/ranking/' + id, {})
            .then((_) => {
                queryClient.invalidateQueries(QK_RANKING);
            })
            .catch((reason) => {
                setErrorMsg(reason);
            })
    }

    const onMoveToPlanned = (id: number) => {
        apiDelete<RoadmapRankingResponse>('/my/roadmap/ranking/' + id)
            .then((_) => {
                queryClient.invalidateQueries(QK_RANKING);
            })
            .catch((reason) => {
                setErrorMsg(reason);
            })
    }

    const onVoteChangePosition = (id: number, position: number) => {
        console.log("change pos", id, position);
        apiPut<RoadmapRankingResponse>('/my/roadmap/ranking/' + id, { position: position })
            .then((_) => {
                queryClient.invalidateQueries(QK_RANKING);
            })
            .catch((reason) => {
                setErrorMsg(reason);
            })
    }

    return (
        <Grid hasGutter>
            {errorMsg && <GridItem span={12}><Alert title={errorMsg}></Alert></GridItem>}
            <GridItem span={6}>
                <Title headingLevel={"h2"} >Your favorites</Title><br />
                <Grid hasGutter>
                    {userVotes
                        .map(uv => features.find(f => f.id === uv) || { id: 0, title: "", description: "" })
                        .map((f, idx) => <GridItem key={f.id}><FeatureCard
                            id={f.id}
                            sortable={true}
                            title={f.title}
                            description={f.description}
                            position={idx + 1}
                            onMoveToFavorites={onMoveToFavorites}
                            onMoveToPlanned={onMoveToPlanned}
                            onVoteUp={onVoteChangePosition}
                            onVoteDown={onVoteChangePosition} /></GridItem>)}
                </Grid>
            </GridItem>
            <GridItem span={6}>
                <Title headingLevel={"h2"} >Planned</Title><br />
                <Grid hasGutter>
                    {planned
                        .map(uv => features.find(f => f.id === uv) || { id: 0, title: "", description: "" })
                        .map((f, idx) => <GridItem key={f.id}><FeatureCard
                            id={f.id}
                            title={f.title}
                            description={f.description}
                            position={idx + 1}
                            onMoveToFavorites={onMoveToFavorites}
                            onMoveToPlanned={onMoveToPlanned}
                            onVoteUp={onVoteChangePosition}
                            onVoteDown={onVoteChangePosition} /></GridItem>)}
                </Grid>
            </GridItem>
        </Grid>
    )
}

const RoadmapPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    return (
        <PageSection className="centered" variant="default">
            <Title headingLevel="h1">{t('roadmap.title')}</Title><br />
            <FeatureGrid />
        </PageSection >
    )
}

export { RoadmapPage }
