"use strict";
exports.__esModule = true;
require('./simple-list.css');
exports.default = {
  "modifiers": {
    "current": "pf-m-current"
  },
  "simpleList": "pf-c-simple-list",
  "simpleListItemLink": "pf-c-simple-list__item-link",
  "simpleListSection": "pf-c-simple-list__section",
  "simpleListTitle": "pf-c-simple-list__title",
  "themeDark": "pf-theme-dark"
};