import * as React from "react";
import {
    Form, ActionGroup, Alert, Button, FormAlert, FormGroup, FormSelect, FormSelectOption,
    InputGroup, List, ListItem, PageSection, Spinner, TextInput, Title, ValidatedOptions
} from "@patternfly/react-core";
import { TrashIcon, PlusIcon } from "@patternfly/react-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Distribution, apiGet, DistributionsResponse, Node, CreateNodeRequest, apiPost, ModifyNodeRequest, apiPut } from "linbit-api-fetcher";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import * as styles from './mei.module.css';

interface SelectListProps {
    id: string;
    values: Array<string>;
    onListChange: (vals: Array<string>) => void;
    inputRegex?: string;
    maxLength?: number;
    style?: React.CSSProperties | undefined;
}

const SelectList: React.FunctionComponent<SelectListProps> = (props: SelectListProps) => {
    const [editValue, setEditValue] = React.useState('');
    const [inputValidated, setInputValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);

    const handleAddValue = () => {
        let newVals = [...props.values];
        newVals.push(editValue);
        setEditValue('')
        props.onListChange(newVals);
        setInputValidated(ValidatedOptions.default);
    }

    const handleRemoveValue = (idx: number) => {
        let newVals = [...props.values];
        newVals.splice(idx, 1);
        props.onListChange(newVals);
    }

    const onKeyPressEditValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValidated === ValidatedOptions.success) {
            handleAddValue();
        }
    }

    const onInputChange = (val: string) => {
        setInputValidated(isInputValid(val));
        setEditValue(val);
    }

    const isInputValid = (input: string) => {
        if (input && props.inputRegex) {
            const regex = new RegExp(props.inputRegex);
            if (!input || regex.test(input) === false) {
                return ValidatedOptions.error;
            }
            return ValidatedOptions.success;
        }
        return ValidatedOptions.default;
    }

    return (
        <React.Fragment><List isPlain>
            {props.values.map((v, idx) =>
                <ListItem key={idx} style={props.style}>{v}
                    <Button
                        variant='plain'
                        className={styles.smallpadding}
                        isSmall
                        onClick={() => handleRemoveValue(idx)}><TrashIcon /></Button>
                </ListItem>)}
        </List>
            <InputGroup>
                <TextInput
                    name="addtolist"
                    style={props.style}
                    id={props.id}
                    type="text"
                    aria-label="add value"
                    value={editValue}
                    placeholder='new value'
                    onChange={onInputChange}
                    onKeyPress={onKeyPressEditValue}
                    maxLength={props.maxLength}
                    validated={inputValidated} />
                <Button
                    variant="control"
                    aria-label="add value button"
                    onClick={handleAddValue}
                    isDisabled={inputValidated !== ValidatedOptions.success}>
                    <PlusIcon />
                </Button>
            </InputGroup>
        </React.Fragment>
    )
}

interface DistributionSelectProps {
    distributionId: number,
    onChange: (distriId: number) => void,
}

const DistributionSelect: React.FunctionComponent<DistributionSelectProps> = (props: DistributionSelectProps) => {
    const { t, i18n } = useTranslation();

    const onChangeDistribution = (val: string) => {
        props.onChange(+val);
    }

    async function fetchDistributions(): Promise<Map<number, Distribution>> {
        let uri = '/my/distributions';
        return apiGet<DistributionsResponse>(uri)
            .then((distriResp) => {
                return distriResp.list
                    .reduce((opts, co) => {
                        opts.set(co.id, co);
                        return opts;
                    }, new Map<number, Distribution>());
            });
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, Distribution>, string>(['distributions'], fetchDistributions);

    if (isError) {
        return (<span>{error}</span>)
    } else {
        if (isLoading || !data) {
            return (<Spinner isSVG />)
        } else {
            return (<FormGroup
                label={t('contracts.distribution')}
                fieldId="form-distribution">
                <FormSelect id="form-distribution" value={props.distributionId} onChange={onChangeDistribution}>
                    <FormSelectOption key={0} label="<none>" value={0} />
                    {Array.from(data)
                        .filter(([key, distri]) => distri.show || distri.id === props.distributionId)
                        .sort((a, b) => a[1].name.localeCompare(b[1].name))
                        .map(([key, distri]) => {
                            return (<FormSelectOption key={key} label={distri.name} value={key} />)
                        })}
                </FormSelect>
            </FormGroup>)
        }
    }
}

interface NodeFormProps {
    hostname: string;
    macAddresses: string[];
    ibAddresses: string[];
    distributionId: number;
    onHostnameChange: (value: string) => void;
    onMacAddressesChange: (vals: Array<string>) => void;
    onIbAddressesChange: (vals: Array<string>) => void;
    onDistriChanged: (distriId: number) => void;
}

const NodeForm: React.FunctionComponent<NodeFormProps> = (props: NodeFormProps) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <FormGroup label={t('edit-node.hostname')} fieldId='hostname'>
                <TextInput id="hostname" aria-label="hostname input"
                    value={props.hostname} onChange={props.onHostnameChange} />
            </FormGroup>
            <FormGroup label={t('edit-node.mac-addresses')} fieldId='edit-node-macs'>
                <SelectList
                    id="edit-node-macs"
                    style={{ fontFamily: "RedHatMono", fontSize: "0.9em" }}
                    values={props.macAddresses}
                    onListChange={props.onMacAddressesChange}
                    inputRegex="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$"
                    maxLength={17} />
            </FormGroup>
            <FormGroup label={t('edit-node.ib-guids')} fieldId='edit-node-ibs'>
                <SelectList
                    id="edit-node-ibs"
                    style={{ fontFamily: "RedHatMono", fontSize: "0.9em" }}
                    values={props.ibAddresses}
                    onListChange={props.onIbAddressesChange}
                    inputRegex="^0x[0-9A-Fa-f]+"
                    maxLength={18} />
            </FormGroup>
            <DistributionSelect distributionId={props.distributionId} onChange={props.onDistriChanged} />
        </React.Fragment>
    )
}

const EditNodePage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = React.useState('');

    const [hostname, setHostname] = React.useState('');
    const [macAddresses, setMacAddresses] = React.useState<Array<string>>([]);
    const [ibAddresses, setIbAddresses] = React.useState<Array<string>>([]);
    const [distributionId, setDistributionId] = React.useState(0);
    const [originalNode, setOriginalNode] = React.useState<Node | null>(null);

    const isCreate = params.nodeId === undefined;

    React.useEffect(() => {
        if (!isCreate) {
            apiGet<Node>(`/my/contracts/${params.contractId}/clusters/${params.clusterId}/nodes/${params.nodeId}`)
                .then((resp) => {
                    setHostname(resp.hostname ? resp.hostname : "");
                    setMacAddresses(resp.mac_addresses);
                    setIbAddresses(resp.ib_addresses);
                    setDistributionId(resp.distribution_id ? resp.distribution_id : 0);
                    setOriginalNode(resp);
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        } else {
            setOriginalNode(null);
        }
    }, []);

    const onHostnameChange = (value: string) => {
        setHostname(value);
    };

    const onDistriChanged = (distriId: number) => {
        setDistributionId(distriId);
    }

    const onSaveClick = () => {
        const CLUSTER_URL = '/my/contracts/' + params.contractId + '/clusters';
        if (isCreate) {
            const contractId = params.contractId ? +params.contractId : 0;
            let createNodeReq: CreateNodeRequest = {
                hostname: hostname,
                mac_addresses: macAddresses,
                ib_addresses: ibAddresses,
                ip_addresses: [],
                current_contract: contractId,
                // comment: comment,
            }
            if (distributionId !== 0) {
                createNodeReq.distribution_id = distributionId;
            }
            console.log(createNodeReq);
            const NODE_URL = '/my/contracts/' + params.contractId + '/clusters/' + params.clusterId + "/nodes";
            apiPost<Node>(NODE_URL, createNodeReq)
                .then((_resp) => {
                    queryClient.invalidateQueries(
                        ['contracts', params.contractId, 'clusters', params.clusterId]);
                    navigate("/contracts/#cluster-" + params.clusterId);
                })
                .catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        } else {
            let modifyReq: ModifyNodeRequest = {
                hostname: hostname,
                mac_addresses: macAddresses,
                ib_addresses: ibAddresses,
            }
            if (distributionId !== 0) {
                modifyReq.distribution_id = distributionId;
            }
            console.log(modifyReq);

            const NODE_URL = '/my/contracts/' + params.contractId + '/clusters/' + params.clusterId + "/nodes/" + params.nodeId;
            apiPut<Node>(NODE_URL, modifyReq)
                .then((_resp) => {
                    queryClient.invalidateQueries(['contracts', params.contractId, 'clusters']).then((_) => {
                        navigate("/contracts/#cluster-" + params.clusterId);
                    });
                }).catch((reason) => {
                    setErrorMessage(reason.toString());
                });
        }
    }

    const saveDisabled = () => {
        let disabled = hostname ? false : true;
        if (originalNode !== null) {
            const orig_distri_id = originalNode.distribution_id === null ? 0 : originalNode.distribution_id;
            disabled ||= originalNode.hostname === hostname
                && orig_distri_id === distributionId
                && originalNode.mac_addresses.toString() === macAddresses.toString()
                && originalNode.ib_addresses.toString() === ibAddresses.toString();
        }
        return disabled;
    }

    return (
        <PageSection className="centered">
            <Title headingLevel={"h1"}>{isCreate ? t('edit-node.add') : t('edit-node.edit')}{params.nodeId}</Title><br />
            <Form className="centered">
                {errorMessage && (
                    <FormAlert>
                        <Alert
                            variant='danger'
                            title={errorMessage}
                            isInline
                        />
                    </FormAlert>
                )}
                <NodeForm
                    hostname={hostname}
                    macAddresses={macAddresses}
                    ibAddresses={ibAddresses}
                    distributionId={distributionId}
                    onHostnameChange={onHostnameChange}
                    onMacAddressesChange={(vals) => setMacAddresses(vals)}
                    onIbAddressesChange={(vals) => setIbAddresses(vals)}
                    onDistriChanged={onDistriChanged} />
                <ActionGroup>
                    <Button
                        variant="primary"
                        onClick={onSaveClick}
                        isDisabled={saveDisabled()}>{isCreate ? t('contracts.create') : t('common.save')}</Button>
                    <Link to={`/contracts/#cluster-${params.clusterId}`}><Button variant="secondary">{t('common.cancel')}</Button></Link>
                </ActionGroup>
            </Form>
        </PageSection>
    )
}

export { EditNodePage, NodeForm }
